<template>
  <div class="pixel__colors pixel__block-wrap">
    <label class="v-label pixel__title theme--light">{{ $t("designer.available_colors") }}</label>
    <v-row>
      <v-col class="my-0 py-0" cols="12">
        <v-select
          :label="$t('designer.palette')"
          :items="palettes"
          v-model="project.meta.palette"
          item-text="title"
          item-value="_id"
          @change="onPaletteChange"
        ></v-select>
      </v-col>
    </v-row>
    <div class="pixel__block" v-if="currentColor">
      <img class="pixel__color" v-bind:class="{active: currentColor.id === color.id}"
           v-for="(color, index) in availableColors" :src="color.preview_url"
           @click="setCurrentColor(color)" :key="color.id"
           :alt="color.id" :title="color.title">
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';


export default {
  name: 'Colors',
  data() {
    return {};
  },
  computed: {
    ...mapState('colors', ['colors', 'palettes', 'colorsImages']),
    ...mapState('design', ['project', 'currentColor']),

    availableColors() {
      var colorsFiltered = this.colors.filter(x => x.palettes.includes(this.project.meta.palette) && x.is_deleted == false);
      // colorsFiltered.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
      return colorsFiltered
    },
  },

  methods: {
    onPaletteChange() {
      this.$store.commit('design/setColor', this.availableColors[0])
    },
    setCurrentColor(color) {
      this.$store.commit('design/setColor', color)
    }
  }
};
</script>

<style lang="scss" scoped>
.pixel {
  &__block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__color {
    width: 42px;
    height: 42px;
    margin: 0 1px 1px 0;
    cursor: pointer;
    transition: box-shadow .2s ease-in-out;
    border-radius: 6px;

    &.active {
      box-shadow: 0 0 0 2px orange;
    }
  }

  &__current-color {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;

    img {
      margin-left: 12px;
      border-radius: 50%;
    }
  }

}
</style>
